<template>
    <painel titulo="Minerador - Clientes" icone="pi pi-comments" :refreshFunction="obterCliente" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (DE)</label>
                    <Calendar v-model="dataRegistroDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (ATÉ)</label>
                    <Calendar v-model="dataRegistroAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <tabela :data="clientes" dataKey="clienteRegistroId" :globalFilterFields="['codParceiro', 'nomeParceiro']" stateKey="dt-minerador-clientes">
            <template #botoes></template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :cliente="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="dataCadastro" header="Recebido em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="dataRegistro" header="Registro de" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataRegistro, 'DD/MM/YYYY') }}
                    </template>
                </Column>
                <Column field="codParceiro" header="Cod Parceiro" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codParceiro }}
                    </template>
                </Column>
                <Column field="nomeParceiro" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nomeParceiro }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import ClientesServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            clientes: null,
            dataRegistroAte: null,
            dataRegistroDe: null,
            carregado: false,
        };
    },

    methods: {
        obterClientes() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataRegistroDe: this.$moment(this.dataRegistroDe).format('YYYY-MM-DD'),
                dataRegistroAte: this.$moment(this.dataRegistroAte).format('YYYY-MM-DD'),
            };
            ClientesServices.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.clientes = response.data;
                } else {
                    this.clientes = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterClientes();
        },
    },

    mounted() {
        this.dataRegistroAte = new Date();
        this.dataRegistroDe = new Date(this.$moment(this.dataRegistroAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterClientes();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Minerador_Clientes';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Leads') {
                if (this.$store.getters.atualizar) {
                    this.obterClientes();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        dataRegistroDe() {
            if (this.carregado) {
                this.obterClientes();
            }
        },

        dataRegistroAte() {
            if (this.carregado) {
                this.obterClientes();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
