<template>
    <font-awesome-icon
        icon="info-circle"
        @click="toDetalhar()"
        size="lg"
        class="icon-button p-text-primary"
        title="Detalhar"
        v-if="$temAcessoView('MINERADOR-CLIENTES-00')"
    />
</template>

<script>
export default {
    props: {
        cliente: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            this.$router.push({
                name: 'Minerador_Clientes_Detalhar',
                params: {
                    id: this.cliente.clienteRegistroId,
                },
            });
        },
    },
};
</script>
